var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"main__navigation"},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('a',{staticClass:"logo",attrs:{"href":"/"}},[_c('img',{attrs:{"src":require("@/assets/logo.png"),"width":"100","height":"40"}})]),_c('v-spacer'),_c('v-toolbar-items',{staticClass:"hidden-sm-and-down"},[_c('v-btn',{staticClass:"toolbar__item",attrs:{"to":'/',"text":"","max-width":"200"}},[_vm._v(" Главная ")]),_c('v-menu',{staticClass:"mt-3",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"toolbar__item",attrs:{"text":"","max-width":"200"}},'v-btn',attrs,false),on),[_vm._v(" Аренда коммерческой недвижимости ")])]}}])},[_c('div',{staticClass:"objects-categories"},_vm._l((_vm.rentList),function(item,index){return _c('v-btn',{key:index,staticClass:"object-btn",attrs:{"text":"","to":item.link}},[_vm._v(" "+_vm._s(item.title)+" ")])}),1)]),_c('v-menu',{staticClass:"mt-3",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"toolbar__item",attrs:{"text":"","max-width":"200"}},'v-btn',attrs,false),on),[_vm._v(" Продажа коммерческой недвижимости ")])]}}])},[_c('div',{staticClass:"objects-categories"},_vm._l((_vm.items2),function(item,index){return _c('v-btn',{key:index,staticClass:"object-btn",attrs:{"text":"","to":item.link}},[_vm._v(" "+_vm._s(item.title)+" ")])}),1)]),_c('v-btn',{staticClass:"toolbar__item",attrs:{"to":"/portfolio","text":"","max-width":"200"}},[_vm._v(" Реализованные объекты ")]),_c('v-btn',{staticClass:"toolbar__item",attrs:{"to":"/o-kompanii","text":"","max-width":"200"}},[_vm._v(" О компании ")]),_c('v-btn',{staticClass:"toolbar__item",attrs:{"to":"/izbrannye","text":"","max-width":"200"}},[_vm._v(" Избранные ")])],1),_c('v-menu',{staticClass:"menu",attrs:{"close-on-content-click":false,"offset-y":"","transition":"fab-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"hidden-md-and-up menu-activator"},'v-btn',attrs,false),on),[_vm._v(" Меню ")])]}}])},[_c('v-list',{attrs:{"color":"menu-list"}},[_c('v-btn',{staticClass:"toolbar__item",attrs:{"to":'/',"text":"","max-width":"200"}},[_vm._v(" Главная ")]),_c('v-menu',{staticClass:"mt-3",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"toolbar__item",attrs:{"text":"","max-width":"200"}},'v-btn',attrs,false),on),[_vm._v(" Аренда объектов ")])]}}])},[_c('div',{staticClass:"objects-categories"},_vm._l((_vm.rentList),function(item,index){return _c('v-btn',{key:index,staticClass:"object-btn",attrs:{"text":"","to":item.link}},[_vm._v(" "+_vm._s(item.title)+" ")])}),1)]),_c('v-menu',{staticClass:"mt-3",attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"toolbar__item",attrs:{"text":"","max-width":"200"}},'v-btn',attrs,false),on),[_vm._v(" Продажа объектов ")])]}}])},[_c('div',{staticClass:"objects-categories"},_vm._l((_vm.items2),function(item,index){return _c('v-btn',{key:index,staticClass:"object-btn",attrs:{"text":"","to":item.link}},[_vm._v(" "+_vm._s(item.title)+" ")])}),1)]),_c('v-btn',{staticClass:"toolbar__item",attrs:{"to":"/portfolio","text":"","max-width":"200"}},[_vm._v(" Реализованные объекты ")]),_c('v-btn',{staticClass:"toolbar__item",attrs:{"to":"/o-kompanii","text":"","max-width":"200"}},[_vm._v(" О компании ")]),_c('v-btn',{staticClass:"toolbar__item",attrs:{"to":"/izbrannye","text":"","max-width":"200"}},[_vm._v(" Избранные ")])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }