<template>
  <AdminLogin />
</template>

<script>
import AdminLogin from "../../components/Admin/Login";
export default {
  components: {
    AdminLogin
  },
  metaInfo: {
    title: "Login"
  }
};
</script>
